.btn {
  position: relative;
  padding: 10px 20px;
  border-color: transparent;
  background-color: transparent;
  color: $white;
  font-family: $font-button;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 2px;
  line-height: 1;
  text-transform: uppercase;
  transition: all 0.3s ease;

  &:hover,
  &:active,
  &:focus {
    border-color: transparent;
    background-color: transparent;
    letter-spacing: 3px;
  }

  * {
    position: relative;
    z-index: 1;
  }

  @include media-breakpoint-only(md) {
    font-size: 30px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 36px;
  }

  &::before {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $red;
    border-radius: 20px;
    box-shadow: 4px 4px 0 $yellow;
    content: ' ';
    transform: skew(-10deg) translateY(-0.1em);
    transition: background-color 0.3s ease;
  }

  &.btn-white {
    color: $primary;

    &::before {
      background-color: $white;
    }

    &:hover {
      color: $orange;

      &::before {
        background-color: $white;
      }
    }
  }

  &.btn-red {
    color: $white !important;
  }

  &.btn-green {
    color: $white !important;

    &::before {
      background-color: $green;
    }
  }

  &:hover {
    &::before {
      background-color: $orange;
    }
  }

  &.btn-mini {
    font-size: 18px;
  }

  &.btn-sm {
    @include media-breakpoint-only(md) {
      font-size: 28px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 32px;
    }
  }

  &.btn-md {
    padding: 12px 20px;

    @include media-breakpoint-only(md) {
      padding: 14px 25px;
    }

    @include media-breakpoint-up(lg) {
      padding: 15px 30px;
    }
  }

  &.btn-lg {
    padding: 15px 20px;

    @include media-breakpoint-only(md) {
      padding: 18px 30px;
    }

    @include media-breakpoint-up(lg) {
      padding: 20px 40px;
    }
  }

  svg {
    position: relative;
    top: -1px;
    padding-left: 0.4em;
    font-size: 0.9em;
  }
}
