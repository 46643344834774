//
// set our own variables and override bootstrap ones here.
// if you need to referene any bootstrap ones, copy from here:
// https://github.com/twbs/bootstrap/blob/v5.0.0-beta1/scss/_variables.scss
//

// spacing
$spacer: 1rem;
$section-padding: $spacer * 3;
$section-padding-mobile: $spacer * 1.5;

// type
$font-sans: 'Open Sans', sans-serif;
//$font-serif: 'Open Sans', serif;
$font-heading: 'Baloo Tamma 2', sans-serif;
$font-button: $font-heading;
$font-thin: 300;
$font-normal: 400;
$font-heavy: 500;
$font-bold: 700;

//
// colors
//

$white: #fff;
$black: #262526;
$offwhite: #f5f5f5;
$red: #e6334f;
$orange: #ec5242;
$green: #58a09c;
$yellow: #ecbc3f;
$yellow-light: #f2d78c;
$color-email: $green;
$color-facebook: #3b5998;
$color-twitter: #55acee;
// bootstrap theme colors
// must restate defaults in order to merge in our custom
$primary: $green;
$secondary: $orange;
$dark: #212529;
$light: #f8f9fa;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$body-color: $black; // $text-color
$link-color: $orange;

// Theme Colors
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
  'red': $red,
  'offwhite': $offwhite,
  'orange': $orange,
  'green': $green,
);
$custom-colors: (
  'papayawhip': papayawhip,
  'white': white,
  'black': black,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

//
// bootstrap variable overrides
//

$font-family-sans-serif: $font-sans;
$font-family-base: $font-sans;
$headings-font-family: $font-heading;
$font-size-base: 1rem;
$line-height-base: 1.5;
$grid-gutter-width: 30px;
$border-radius: 0;
$border-radius-lg: 0;
$border-radius-sm: 0;

// customize grid
// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px,
//   xxl: 1141px,
// );
