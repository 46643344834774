.fired-up-embed {
  form {
    max-width: 411px;
    margin-right: auto;
    margin-left: auto;
  }

  .form-control {
    height: 52px;
    padding: 12px;
    padding-left: 20px;
    border: 0;
    border-left: 5px solid $white;
    margin-bottom: 20px;
    transition: 0.3s ease all;

    @include media-breakpoint-up(lg) {
      height: 62px;
    }

    &:focus {
      border-left: 5px solid $yellow;
    }

    &::placeholder {
      display: none;
      visibility: hidden;
    }
  }

  .invalid-feedback {
    margin-bottom: 5px;
    color: $yellow-light;
  }


  .control-label {
    padding-bottom: 5px;
    color: $yellow-light;
    font-weight: 600;
    text-transform: uppercase;
  }
}

.field-type-submit {
  .btn {
    padding: 15px;
    padding-right: 40px;
    padding-left: 40px;
    margin-top: 20px;
    color: $green;
    font-size: 26px;

    @include media-breakpoint-up(lg) {
      font-size: 36px;
    }

    &::before {
      padding-top: 20px;
      background: $white;
      // content: "I'm In";
      font-style: normal !important;
      transition: 0.3s ease all;
    }

    &:hover,
    &:focus {
      &::before {
        background: $white;
        color: $orange;
      }
    }

    &.btn-success {
      border: transparent;
      background: transparent;
    }
  }
}
