// background images

// some section backgrounds ('bg-bla' classes)  are background-image rather than solid colors
// used as background colors for Shared: Flex Shared Options
.bg-blue_tile {
  background-color: #3a8bb9;
  background-image: url('/tile-blue.svg');
  color: $white;
}

.bg-red_tile {
  background-color: $red;
  background-image: url('/featured-content-bg.svg');
  color: $white;
}

// Responsive image by default
img {
  max-width: 100%;
  height: auto;
}

// Undo for google maps
.google-maps img,
.no-max img,
#map_canvas img {
  max-width: none;
}

// fixes layout issues with tracking pixels
iframe[width='0'][height='0'] {
  display: none;
}

.fluid-video {
  aspect-ratio: 16 / 9;

  iframe {
    width: 100%;
    height: 100%;
  }
}
