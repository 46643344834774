// As much as possible, set type by customizing the default bootstrap variables in _variables.scss

h1 {
  font-weight: bold;

  a {
    text-decoration: none;
  }
}
// Bottom margin only

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $font-heading;
  font-weight: $font-bold;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p {
  margin-top: 0;

  // last element shouldn't have margin to prevent doubling up
  &:last-child {
    margin-bottom: 0;
  }
}

// focus

:focus {
  outline: none;
}

.wysiwyg-content {
  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }

  h1 {
    font-size: 40px;

    @include media-breakpoint-up(lg) {
      font-size: 60px;
      line-height: 1;
    }
  }

  h2 {
    font-size: 30px;

    @include media-breakpoint-up(lg) {
      font-size: 46px;
    }
  }

  h3 {
    @include media-breakpoint-up(lg) {
      font-size: 40px;
    }
  }

  ol,
  p,
  ul {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }

  li {
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  blockquote {
    position: relative;
    color: $orange;
    font-family: $font-heading;
    font-size: 18px;
    font-weight: $font-bold;
    line-height: 1.333;

    @include media-breakpoint-only(xs) {
      margin-left: 2.1em;
    }

    @include media-breakpoint-up(lg) {
      font-size: 22px;
    }

    &::before {
      position: absolute;
      top: 0;
      left: -2.1em;
      width: 1.6em;
      height: 1.1em;
      background-image: url(/quotes.svg);
      background-repeat: no-repeat;
      background-size: contain;
      content: ' ';

      @include media-breakpoint-up(md) {
        top: -0.15em;
        left: -2.6em;
        width: 2.2em;
        height: 1.5em;
      }
    }

    &:not(:first-child) {
      margin-top: 2.5em;
    }

    &:not(:last-child) {
      margin-bottom: 2.5em;
    }

    p + p.attribution {
      margin-top: -1em;
    }
  }

  .attribution {
    color: $green;
    font-family: $font-heading;
    font-size: 1.1rem;
    font-weight: $font-heavy;
    text-align: right;
    text-transform: uppercase;
  }
}

#eventContainer {
  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }

  h2 {
    font-size: 30px;

    @include media-breakpoint-up(lg) {
      font-size: 46px;
    }
  }

  ol,
  p,
  ul {
    &:not(:last-child) {
      margin-bottom: 2em;
    }
  }

  li {
    &:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }
}

// suplement bootstrap's class for links (since default red might be too dark to contrast)
.text-white {
  a {
    color: $white;
  }
}
